import customTypes from "../enums/contentsCustomTypes.enum";
import contentsTypes from "cms/enums/contentsTypes.enum";
import CardBox2Preview from "../components/CardBox2/CardBox2Preview";
export var cardBox2 = {
    label: "CardBox 2",
    type: customTypes.CARD_BOX_2,
    icon: "address-card",
    editionModal: true,
    editionModalPosition: "top",
    Preview: CardBox2Preview,
    children: [
        {
            label: "Titre",
            key: "title",
            type: contentsTypes.INPUT,
            value: ""
        },
        {
            label: "Description",
            key: "description",
            type: contentsTypes.SHORT_TEXT,
            value: ""
        },
        {
            label: "Image",
            key: "image",
            type: contentsTypes.IMAGE,
            value: ""
        },
        {
            label: "Position Image Mobile",
            key: "position",
            type: contentsTypes.RADIO_GROUP,
            width: "50%",
            options: [
                { label: "gauche", value: "row" },
                { label: "droite", value: "row-reverse" }
            ],
            value: "row"
        }
    ]
};
