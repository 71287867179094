import customTypes from "../enums/contentsCustomTypes.enum";
import contentsTypes from "cms/enums/contentsTypes.enum";
export var teamUnit = {
    label: "Membre équipe",
    type: customTypes.TEAM_UNIT,
    icon: "address-card",
    editionModal: true,
    editionModalPosition: "top",
    children: [
        {
            label: "Photo",
            key: "image",
            type: contentsTypes.IMAGE,
            value: ""
        },
        {
            label: "Nom",
            key: "title",
            type: contentsTypes.INPUT,
            value: ""
        },
        {
            label: "Rôle",
            key: "subtitle",
            type: contentsTypes.INPUT,
            value: ""
        },
        {
            label: "Description",
            key: "description",
            type: contentsTypes.SHORT_TEXT,
            value: ""
        }
    ]
};
