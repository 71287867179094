import customTypes from "../enums/contentsCustomTypes.enum";
import contentsTypes from "cms/enums/contentsTypes.enum";
import LastArticlesPreview from "../components/Articles/LastArticlesPreview";
export var LastArticles = {
    label: "Derniers articles",
    type: customTypes.LAST_ARTICLES_BOX,
    icon: "newspaper",
    editionModal: true,
    editionModalPosition: "top",
    Preview: LastArticlesPreview,
    children: [
        {
            label: "Blog",
            key: "blog",
            type: contentsTypes.PAGE,
            value: ""
        },
        {
            label: "Titre",
            key: "title",
            type: contentsTypes.INPUT,
            value: ""
        }
    ]
};
