var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Grid from "@mui/material/Grid";
import Button from "cms/components/front-office/contents/Button";
import { Typography } from "@mui/material";
var ButtonPreview = function () {
    return (_jsxs(Grid, __assign({ container: true }, { children: [_jsxs(Grid, __assign({ item: true, container: true }, { children: [_jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(Typography, __assign({ variant: "h4", sx: { pb: 3, pt: 3 } }, { children: "Style Primaire" }), void 0) }), void 0), _jsxs(Grid, __assign({ container: true, item: true, spacing: 4 }, { children: [_jsx(Grid, __assign({ item: true }, { children: _jsx(Button, __assign({ color: "primary" }, { children: "Couleur de base" }), void 0) }), void 0), _jsx(Grid, __assign({ item: true }, { children: _jsx(Button, __assign({ color: "secondary" }, { children: "Couleur secondaire" }), void 0) }), void 0), _jsx(Grid, __assign({ item: true }, { children: _jsx(Button, __assign({ primary: true, icon: "arrow-right" }, { children: "Avec ic\u00F4ne" }), void 0) }), void 0)] }), void 0)] }), void 0), _jsxs(Grid, __assign({ item: true, container: true }, { children: [_jsx(Grid, __assign({ item: true }, { children: _jsx(Typography, __assign({ variant: "h4", sx: { pb: 3, pt: 3 } }, { children: "Style Secondaire" }), void 0) }), void 0), _jsxs(Grid, __assign({ container: true, item: true, spacing: 4 }, { children: [_jsx(Grid, __assign({ item: true }, { children: _jsx(Button, __assign({ variant: "outlined", color: "primary" }, { children: "Couleur de base" }), void 0) }), void 0), _jsx(Grid, __assign({ item: true }, { children: _jsx(Button, __assign({ variant: "outlined", color: "secondary" }, { children: "Couleur secondaire" }), void 0) }), void 0), _jsx(Grid, __assign({ item: true }, { children: _jsx(Button, __assign({ variant: "outlined", primary: true, icon: "arrow-right" }, { children: "Avec ic\u00F4ne" }), void 0) }), void 0)] }), void 0)] }), void 0)] }), void 0));
};
export default ButtonPreview;
