import customTypes from "../enums/contentsCustomTypes.enum";
import contentsTypes from "cms/enums/contentsTypes.enum";
import CardBox1Preview from "../components/CardBox1/CardBox1Preview";
export var cardBox1 = {
    label: "CardBox 1",
    type: customTypes.CARD_BOX_1,
    icon: "address-card",
    editionModal: true,
    editionModalPosition: "top",
    Preview: CardBox1Preview,
    children: [
        {
            label: "Titre",
            key: "title",
            type: contentsTypes.INPUT,
            value: ""
        },
        {
            label: "Description",
            key: "description",
            type: contentsTypes.SHORT_TEXT,
            value: ""
        },
        {
            label: "Image",
            key: "image",
            type: contentsTypes.IMAGE,
            value: ""
        }
    ]
};
