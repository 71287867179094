var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Icon from "@mui/material/Icon";
import AdminTemplateSettingsContext from "cms/components/back-office/AdminTemplateSettings/AdminTemplateSettingsContext";
var actionsStyled = {
    position: "absolute",
    top: "12px",
    left: "12px",
    zIndex: "50",
    p: 0.25,
    cursor: "pointer"
};
var AdminTemplateSettingsActions = function (props) {
    var children = props.children, others = __rest(props, ["children"]);
    var setDisplayAdminTemplateSettingsModal = useContext(AdminTemplateSettingsContext).setDisplayAdminTemplateSettingsModal;
    return (_jsxs(Box, __assign({ sx: { position: "relative", border: "double #2646D4" } }, others, { children: [_jsx(Box, __assign({ sx: actionsStyled }, { children: _jsx(Icon, { onClick: function () { return setDisplayAdminTemplateSettingsModal(true); }, className: "fas fa-cog" }, void 0) }), void 0), children] }), void 0));
};
AdminTemplateSettingsActions.propTypes = {
    children: PropTypes.node.isRequired
};
AdminTemplateSettingsActions.defaultProps = {};
export default AdminTemplateSettingsActions;
