import customTypes from "../enums/contentsCustomTypes.enum";
import contentsTypes from "cms/enums/contentsTypes.enum";
import PresentationRowPreview from "../components/PresentationRow/PresentationRowPreview";
export var PresentationRow = {
    label: "PresentationRow",
    type: customTypes.PRESENTATION_ROW,
    icon: "address-card",
    editionModal: true,
    editionModalPosition: "top",
    Preview: PresentationRowPreview,
    children: [
        {
            label: "Titre",
            key: "title",
            type: contentsTypes.INPUT,
            value: ""
        },
        {
            label: "Image",
            key: "image",
            type: contentsTypes.IMAGE,
            value: ""
        },
        {
            label: "Description",
            key: "description",
            type: contentsTypes.TEXT,
            value: ""
        },
        {
            label: "dynamicPartAfterDescription",
            key: "dynamicPartAfterDescription",
            type: contentsTypes.DYNAMIC,
            value: ""
        }
    ]
};
