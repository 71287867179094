var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled } from "@mui/material";
import ListSubheader from "@mui/material/ListSubheader";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as React from "react";
import { VariableSizeList } from "react-window";
import Icon from "../../../front-office/contents/Icon";
var LISTBOX_PADDING = 8; // px
var StyledListSubheader = styled(ListSubheader)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: ", ";\n  &:hover {\n    background: ", ";\n  }\n  &:active {\n    background: ", ";\n  }\n"], ["\n  background: ", ";\n  &:hover {\n    background: ", ";\n  }\n  &:active {\n    background: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.palette.mode === "light" ? theme.palette.secondary.light : theme.palette["form"].inputBackgroundColor;
}, function (_a) {
    var theme = _a.theme;
    return (theme.palette.mode === "light" ? "#f3f3f5" : "#676767");
}, function (_a) {
    var theme = _a.theme;
    return (theme.palette.mode === "light" ? "#e5eeff" : "#526481");
});
function renderRow(props, withIcon, type) {
    var data = props.data, index = props.index, style = props.style;
    var dataSet = data[index];
    return (_jsxs(StyledListSubheader, __assign({ component: "li" }, dataSet[0], { noWrap: true, style: style }, { children: [_jsx(Typography, __assign({ sx: { width: 30 }, component: "span" }, { children: withIcon && _jsx(Icon, { icon: dataSet[1].icon, type: type }, void 0) }), void 0), _jsx(Typography, __assign({ component: "span" }, { children: dataSet[1].name }), void 0)] }), void 0));
}
var OuterElementContext = React.createContext({});
var OuterElementType = React.forwardRef(function (props, ref) {
    var outerProps = React.useContext(OuterElementContext);
    return _jsx("div", __assign({ ref: ref }, props, outerProps), void 0);
});
function useResetCache(data) {
    var ref = React.useRef(null);
    React.useEffect(function () {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}
// Adapter for react-window
export var ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
    var children = props.children, type = props.type, withIcon = props.withIcon, other = __rest(props, ["children", "type", "withIcon"]);
    var itemData = [];
    children.forEach(function (item) {
        itemData.push(item);
        itemData.push.apply(itemData, (item.children || []));
    });
    var theme = useTheme();
    var smUp = useMediaQuery(theme.breakpoints.up("sm"), {
        noSsr: true
    });
    var itemCount = itemData.length;
    var itemSize = smUp ? 36 : 48;
    var getChildSize = function (child) {
        if (child.hasOwnProperty("group")) {
            return 48;
        }
        return itemSize;
    };
    var getHeight = function () {
        if (itemCount > LISTBOX_PADDING) {
            return LISTBOX_PADDING * itemSize;
        }
        return itemData.map(getChildSize).reduce(function (a, b) { return a + b; }, 0);
    };
    var gridRef = useResetCache(itemCount);
    return (_jsx("div", __assign({ ref: ref }, { children: _jsx(OuterElementContext.Provider, __assign({ value: __assign(__assign({}, other), theme) }, { children: _jsx(VariableSizeList, __assign({ itemData: itemData, height: getHeight() + 2 * LISTBOX_PADDING, width: "100%", ref: gridRef, outerElementType: OuterElementType, innerElementType: "ul", itemSize: function (index) { return getChildSize(itemData[index]); }, overscanCount: 5, itemCount: itemCount }, { children: function (props) {
                    return renderRow(props, withIcon, type);
                } }), void 0) }), void 0) }), void 0));
});
var templateObject_1;
