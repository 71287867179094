var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState, useEffect } from "react";
import { createQueryParams, getQueryParams } from "cms/utils/urlUtils";
import { useLocation, useHistory } from "react-router-dom";
import AdminPageContext from "./AdminPageContext";
import AdminPageVersionsService from "cms/services/admin/AdminPageVersionsService";
import AdminPagesService from "cms/services/admin/AdminPagesService";
import { queryParamSiteKey, queryParamPageKey } from "cms/utils/queryParamsKeys";
export var AdminPageContextProvider = function (props) {
    var children = props.children;
    var _a = useState(null), currentPage = _a[0], setCurrentPage = _a[1];
    var location = useLocation();
    var pathname = location.pathname;
    var history = useHistory();
    var historyPush = history.push;
    var queryParams = getQueryParams();
    var _b = queryParams, _c = queryParamSiteKey, siteId = _b[_c], _d = queryParamPageKey, queryParamPage = _b[_d];
    var handleChangePage = React.useCallback(function (page) {
        var _a;
        var id = page.id;
        setCurrentPage(id);
        historyPush(pathname + "?" + createQueryParams((_a = {},
            _a[queryParamSiteKey] = siteId,
            _a[queryParamPageKey] = id,
            _a)));
    }, [historyPush, pathname, siteId]);
    var goToHomePage = React.useCallback(function () {
        if (siteId) {
            AdminPageVersionsService.find({
                params: {
                    join: ["page"],
                    filter: ["path||eq||/", "page.siteId||eq||" + siteId, "page.parentId||isnull"]
                }
            }).then(function (versions) {
                var version = versions[0];
                if (version) {
                    handleChangePage(version.page);
                }
            });
        }
    }, [siteId, handleChangePage]);
    useEffect(function () {
        if (queryParamPage) {
            AdminPagesService.get(queryParamPage)
                .then(setCurrentPage)
                .catch(function () { return goToHomePage(); });
        }
    }, [goToHomePage, queryParamPage]);
    return (_jsx(AdminPageContext.Provider, __assign({ value: {
            currentPage: currentPage,
            setCurrentPage: handleChangePage,
            goToHomePage: goToHomePage
        } }, { children: children }), void 0));
};
